import _core2 from "@styled-system/core";
var exports = {};
exports.__esModule = true;
exports["default"] = exports.space = exports.padding = exports.margin = void 0;
var _core = _core2;
var defaults = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512]
};

var isNumber = function isNumber(n) {
  return typeof n === "number" && !isNaN(n);
};

var getMargin = function getMargin(n, scale) {
  if (!isNumber(n)) {
    return (0, _core.get)(scale, n, n);
  }

  var isNegative = n < 0;
  var absolute = Math.abs(n);
  var value = (0, _core.get)(scale, absolute, absolute);

  if (!isNumber(value)) {
    return isNegative ? "-" + value : value;
  }

  return value * (isNegative ? -1 : 1);
};

var configs = {};
configs.margin = {
  margin: {
    property: "margin",
    scale: "space",
    transform: getMargin,
    defaultScale: defaults.space
  },
  marginTop: {
    property: "marginTop",
    scale: "space",
    transform: getMargin,
    defaultScale: defaults.space
  },
  marginRight: {
    property: "marginRight",
    scale: "space",
    transform: getMargin,
    defaultScale: defaults.space
  },
  marginBottom: {
    property: "marginBottom",
    scale: "space",
    transform: getMargin,
    defaultScale: defaults.space
  },
  marginLeft: {
    property: "marginLeft",
    scale: "space",
    transform: getMargin,
    defaultScale: defaults.space
  },
  marginX: {
    properties: ["marginLeft", "marginRight"],
    scale: "space",
    transform: getMargin,
    defaultScale: defaults.space
  },
  marginY: {
    properties: ["marginTop", "marginBottom"],
    scale: "space",
    transform: getMargin,
    defaultScale: defaults.space
  }
};
configs.margin.m = configs.margin.margin;
configs.margin.mt = configs.margin.marginTop;
configs.margin.mr = configs.margin.marginRight;
configs.margin.mb = configs.margin.marginBottom;
configs.margin.ml = configs.margin.marginLeft;
configs.margin.mx = configs.margin.marginX;
configs.margin.my = configs.margin.marginY;
configs.padding = {
  padding: {
    property: "padding",
    scale: "space",
    defaultScale: defaults.space
  },
  paddingTop: {
    property: "paddingTop",
    scale: "space",
    defaultScale: defaults.space
  },
  paddingRight: {
    property: "paddingRight",
    scale: "space",
    defaultScale: defaults.space
  },
  paddingBottom: {
    property: "paddingBottom",
    scale: "space",
    defaultScale: defaults.space
  },
  paddingLeft: {
    property: "paddingLeft",
    scale: "space",
    defaultScale: defaults.space
  },
  paddingX: {
    properties: ["paddingLeft", "paddingRight"],
    scale: "space",
    defaultScale: defaults.space
  },
  paddingY: {
    properties: ["paddingTop", "paddingBottom"],
    scale: "space",
    defaultScale: defaults.space
  }
};
configs.padding.p = configs.padding.padding;
configs.padding.pt = configs.padding.paddingTop;
configs.padding.pr = configs.padding.paddingRight;
configs.padding.pb = configs.padding.paddingBottom;
configs.padding.pl = configs.padding.paddingLeft;
configs.padding.px = configs.padding.paddingX;
configs.padding.py = configs.padding.paddingY;
var margin = (0, _core.system)(configs.margin);
exports.margin = margin;
var padding = (0, _core.system)(configs.padding);
exports.padding = padding;
var space = (0, _core.compose)(margin, padding);
exports.space = space;
var _default = space;
exports["default"] = _default;
export default exports;
export const __esModule = exports.__esModule;
const _space = exports.space,
      _padding = exports.padding,
      _margin = exports.margin;
export { _space as space, _padding as padding, _margin as margin };